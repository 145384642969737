// ManuscriptStatusCheck.js
import React, { useState } from 'react';
import getFirebaseInstance from '../components/Firebase'; // Adjust the path to your firebase.js
import { doc, getDoc } from 'firebase/firestore';
import '../assets/css/userpage.css'; // Import CSS styles

const ManuscriptStatusCheck = () => {
  const [manuscriptId, setManuscriptId] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');

  const firebase = getFirebaseInstance();

  const checkManuscriptStatus = async () => {
    if (!manuscriptId) {
      setError('Please enter a manuscript ID');
      return;
    }

    try {
      const docRef = doc(firebase.db, 'ReviewingManuscripts', manuscriptId); // Adjust collection name if needed
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setStatus('Under Review'); // Set status based on document existence or its fields
        setError('');
      } else {
        setStatus('Pending'); // Set status based on document absence
        setError('');
      }
    } catch (error) {
      console.error('Error checking manuscript status:', error);
      setStatus('Error');
      setError('Error fetching manuscript status');
    }
  };

  return (
    <div className="container">
      <h2>Manuscript Status Check</h2>
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter Manuscript ID"
          value={manuscriptId}
          onChange={(e) => setManuscriptId(e.target.value)}
        />
      </div>
      <div className="button-container">
        <button onClick={checkManuscriptStatus}>Check Status</button>
      </div>
      {status && (
        <div className="status-container">
          <h3>Status: {status}</h3>
        </div>
      )}
      {error && (
        <div className="error-container">
          <p>Error: {error}</p>
        </div>
      )}
    </div>
  );
};

export default ManuscriptStatusCheck;
